export default {
	data: () => ({
		airport: {
			id: 0,
			country: "",
		},
		loading: false,
		error: "",
		backUrl: "/airports"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.airport = {
				id: 0,
				country: "",
			};
		},
		validateAirport() {
			this.airport.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		update() {
			let _vm = this;
			// Making a POST request to update the airport data
			this.axios
				.post("/airport/" + this.airport.id, {
					...this.airport,
					_method: "PATCH"
				})
				.then(function (response) {
					// Updating the airport data with the response data
					_vm.airport = response.data.data;
					_vm.$router.push(_vm.backUrl); // Redirecting to the specified URL
				})
				.catch(function () {
					// Error handling
				});
		},

		add() {
			let _vm = this;
			// Sending a POST request to the '/airport' endpoint
			this.axios
				.post("/airport", _vm.airport)
				.then(function (response) {
					// Redirecting to the specified 'backUrl'
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			// Fetch airport details using the provided ID
			let _vm = this;
			this.axios
				.get("/airport/" + this.$route.params.id)
				.then(function (response) {
					// Assign the retrieved data to the 'airport' variable
					_vm.airport = response.data.data;
				})
				.catch(function () { });
		},


	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
